import axios from 'axios';
import config from '@/config';
import store from '@/store';
import router from '@/router';

axios.defaults.baseURL = config.apiUrl;

axios.interceptors.response.use(null, function(error) {
  if (error.response) {
    if (error.response.status === 401) {
      window.location.href = `${config.ssoUrl}?redirect=${window.location.href}`;
    } else if (error.response.status === 403 && router.currentRoute.name !== 'forbidden') {
      router.push({ name: 'forbidden' });
    }
  }
  return Promise.reject(error);
});

window.addEventListener('tokenLoaded', e => {
  const token = e.detail;

  if (token == null) {
    store.commit('setIsLoggedIn', false);
    store.commit('setTokenReady', true);
    return;
  }

  if (!decodeToken(token).domains.includes("geminio")) {
    store.commit('setHasAccessToDomain', false);
  }

  axios.defaults.headers.common["X-Authorization"] = `Bearer ${token}`;

  store.commit('setIsLoggedIn', true);
  store.commit('setTokenReady', true);
  store.dispatch('fetchUser');
});

function decodeToken(token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export default {
  axios
}
