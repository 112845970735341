import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: () => import(/* webpackChunkName: "landing-page" */ '../views/LandingPage.vue')
  },
  {
    path: '/file/:slug',
    name: 'ViewFile',
    props: true,
    component: () => import(/* webpackChunkName: "view-file" */ '../views/ViewFile.vue')
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "forbidden" */ '../views/Forbidden.vue')
  },
  {
    path: '/my-files',
    name: 'MyFiles',
    component: () => import(/* webpackChunkName: "my-files" */ '../views/MyFiles.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
