<template>
  <div class="container">
    <nav class="navbar is-spaced" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item is-size-4">
          Geminio
        </router-link>

        <div class="navbar-burger" @click="showNav = !showNav" :class="{ 'is-active': showNav }">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      <div id="navbar" class="navbar-menu" :class="{ 'is-active': showNav }">
        <div class="navbar-end">
          <template v-if="isLoggedIn">
            <div class="navbar-item has-dropdown is-hoverable">
              <a href="javascript:void(0)" class="navbar-link">
                <i class="fas fa-user"></i>
              </a>

              <div class="navbar-dropdown is-right">
                <div class="navbar-item" v-if="user">
                  {{ user.firstname }} {{ user.lastname }}
                </div>
                <template v-if="hasAccessToDomain">
                  <hr class="navbar-divider">
                  <router-link to="/my-files" class="navbar-item" v-if="hasAccessToDomain">
                    My files
                  </router-link>
                </template>
                <hr class="navbar-divider">
                <a class="navbar-item" :href="logoutUrl">
                  Logout
                </a>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="button is-primary" @click="login">
              Login
            </div>
          </template>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../config";

export default {
  name: "Navbar",
  computed: {
    logoutUrl() {
      return `${config.ssoUrl}/logout`;
    },
    ...mapGetters(['isLoggedIn', 'user', 'hasAccessToDomain'])
  },
  data() {
    return {
      showNav: false
    }
  },
  methods: {
    login() {
      window.location.href = `${config.ssoUrl}?redirect=${window.location.href}`;
    }
  }
}
</script>

<style scoped>

</style>
