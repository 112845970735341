import Vue from 'vue'
import Vuex from 'vuex'
import http from "../services/http";
import config from "../config";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    user: null,
    hasAccessToDomain: true,
    tokenReady: false,
    isLoading: false
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    user: state => state.user,
    hasAccessToDomain: state => state.hasAccessToDomain,
    tokenReady: state => state.tokenReady,
    isLoading: state => state.isLoading
  },
  mutations: {
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setUser(state, user) {
      state.user = user;
    },
    setHasAccessToDomain(state, hasAccessToDomain) {
      state.hasAccessToDomain = hasAccessToDomain;
    },
    setTokenReady(state, tokenReady) {
      state.tokenReady = tokenReady;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    }
  },
  actions: {
    fetchUser({ commit }) {
      return new Promise((resolve, reject) => {
        http.axios.get(`${config.ssoBackendUrl}/me`).then(res => {
          commit('setUser', res.data);
          resolve();
        }).catch(e => reject(e));
      });
    }
  }
})
