// const apiUrl = 'http://localhost:5000/api';
// const ssoUrl = 'http://localhost:8080';
// const ssoBackendUrl = 'http://localhost:8000/api/v1';
const apiUrl = '/api';
const ssoUrl = 'https://login.roelink.eu';
const ssoBackendUrl = 'https://sso.roelink.eu/api/v1';

export default {
  apiUrl,
  ssoUrl,
  ssoBackendUrl
};
