<template>
  <div id="app">
    <navbar></navbar>
    <div class="notification is-danger is-light small-notification" v-if="!hasAccessToDomain">You don't have access to this domain.</div>
    <router-view/>
    <loader v-if="isLoading"/>
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import { mapGetters } from "vuex";
import Loader from "./components/Loader";
export default {
  components: { Loader, Navbar },
  computed: {
    ...mapGetters(['hasAccessToDomain', 'isLoading'])
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

.small-notification {
  padding: 5px;
  text-align: center;
}
</style>
